import { useTheme } from '@emotion/react';
import { Picture } from '../picture/picture';
import { DescriptionItem } from '../posts/styled';
import LocationSVG from '../../public/img/location.svg';
import ViewsSVG from '../../public/img/views2.svg';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { Link } from '../styled/Link';
import { Place } from '../../models';
import FavoriteButton from '../favorite/FavoriteButton';
import FavoriteButtonCardWrapper from '../favorite/FavoriteButtonCardWrapper';
import { FavoriteKinds } from '../../models/favorite';
import { ImageSources } from '../../models/image';

export function PlaceCard({
  place,
  className = '',
}: {
  place: Place;
  className?: string;
}) {
  const theme = useTheme();
  const onFormatEventTitle = (count: number) => {
    if (count === 1) {
      return 'событие';
    }
    if (count > 1 && count < 5) {
      return 'события';
    }
    return 'событий';
  };
  return (
    <div
      // actions__item actions__item_scroll
      itemScope
      itemType='https://schema.org/Place'
      className={`actions__item ${className}`}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        marginBottom: '16px',
        paddingLeft: 8,
        paddingRight: 8,
        [DEVICE_SCREENS_MAX_STRING.md]: {
          minHeight: 400,
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          height: '100%',
        }}
      >
        <div
          // action-card
          css={{
            height: '100%',
            borderRadius: '4px',
            backgroundColor: '#fff',
            position: 'relative',
            top: 0,
            width: '100%',
            transition: 'all 0.3s ease',
            display: 'flex',
            flexDirection: 'column',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: theme.constants.boxShadow,
            },
          }}
        >
          <Link
            // action-card__image-wrapper
            itemProp='url'
            href={['', 'places', place.slug, ''].join('/')}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              height: 0,
              width: '100%',
              paddingBottom: '75%',
              position: 'relative',
              display: 'block',
              '&:hover': {
                color: theme.colors.black,
              },
            }}
          >
            {place.images && place.images[0] && (
              <div
                // action-card__image
                css={{
                  borderRadius: '4px',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  overflow: 'hidden',
                }}
              >
                <Picture
                  src={place.images[0] as ImageSources}
                  alt={place.title}
                  is360
                />
              </div>
            )}
            {place.events_count && (
              <div
                // action-card__events-counter
                css={{
                  fontFamily: 'Golos',
                  position: 'absolute',
                  left: '8px',
                  bottom: '8px',
                  background: 'rgba(40, 40, 40, 0.7)',
                  borderRadius: '4px',
                  color: theme.colors.white,
                  padding: '8px 16px',
                }}
              >
                {place.events_count} {onFormatEventTitle(place.events_count)}
              </div>
            )}
          </Link>
          <FavoriteButtonCardWrapper>
            <FavoriteButton
              kind={FavoriteKinds.place}
              id={place.remote_id}
              size='small'
              variant='red-outline'
            />
          </FavoriteButtonCardWrapper>
          <div
            // action-card__info
            css={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '14px 14px 24px',
            }}
          >
            <div
              // action-card__description
              css={{
                color: '#000',
                fontFamily: 'Golos',
                fontWeight: 400,
                fontSize: '12px',
                '&>div': {
                  marginBottom: '6px',
                },
              }}
            >
              {place.addresses && !!place.addresses.length && (
                <DescriptionItem>
                  <LocationSVG
                    fill={theme.colors.red}
                    css={{ width: '16px', height: '16px' }}
                  />
                  <span itemProp='address'>{place.addresses[0]}</span>
                </DescriptionItem>
              )}
              <span itemProp='telephone' css={{ display: 'none' }}>
                {place.phone_number || ''}
              </span>
            </div>
            <Link
              // action-card__title
              href={['', 'places', place.slug, ''].join('/')}
              css={{
                fontFamily: 'Montserrat',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '22px',
                letterSpacing: '0em',
                color: theme.colors.black,
                textDecoration: 'none',
                marginBottom: 10,
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                '&:hover': {
                  color: theme.colors.black,
                },
              }}
            >
              <span itemProp='name'>{place.title}</span>
            </Link>
            <div
              css={{
                position: 'absolute',
                bottom: '12px',
                right: '20px',
                left: '20px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                color: theme.colors.grayStrong,
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '18px',
                  height: '16px',
                  fontFamily: 'Golos',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '16px',
                  letterSpacing: '0.01em',
                  svg: {
                    flex: '0 0 auto',
                    marginRight: '5px',
                  },
                }}
              >
                <ViewsSVG
                  fill={theme.colors.grayStrong}
                  css={{ width: '16px', height: '16px' }}
                />
                <span>{place.views}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
